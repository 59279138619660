.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paper p {
  padding: 0 16px 16px;
}
