.buttons {
  display: flex;
  align-items: center;
  gap: 1em;
}

.jsontextarea {
  min-width: 540px;
  font-family: monospace;
}
